<template>
  <div class="w-full font-NotoSansSc">
    <div 
      class="mx-auto font-black text-center text-black
        h-[43px] text-[34px] leading-[1.26] tracking-[3px]
        tablet:h-[60px] tablet:text-[49px] tablet:leading-[1.22] tablet:tracking-[4px]"
    >
      <p>游戏提交完毕!</p>
    </div>
    <!-- <div class="h-[1px] my-6 bg-gray-200" /> -->
    <div class="mt-3 text-center text-gray-600 text-[15px] leading-[1.6] -tracking-[0.09px] tablet:mt-5 tablet:text-xl">
      谢谢您的支持。我们将尽快跟您联络。
    </div>
    <div class="mt-6 tablet:mt-8 w-full text-center">
      <router-link 
        to="/" 
      >
        <button
          type="submit"
          class=" bg-orange-500 font-bold  text-white hover:bg-orange-600
            w-[152px] h-12 px-9 pt-[11px] pb-[13px] rounded-[24px] text-lg leading-[1.33]
            tablet:w-[224px] tablet:h-16 tablet:px-16 tablet:pt-4 tablet:pb-[18px] tablet:rounded-5xl tablet:text-[24px] tablet:leading-[30px] tablet:-tracking-[0.2px]"
        >
          返回主页
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompleteChi',
};
</script>
