<template>
  <div class="flex justify-center items-center min-h-[506px] h-[calc(100vh-401px)] tablet:h-[calc(100vh-420px)] xl:h-[calc(100vh-347px)]">
    <div 
      class="absolute left-1/2 top-0 -translate-x-1/2 z-10 w-full h-1/2 translate-y-[12%]
        tablet:translate-y-[13%] xl:w-1/2 xl:h-1/2 xl:translate-y-[16%]"
    >
      <lottie
        :options="defaultOptions"
        @animCreated="handleAnimation"
      />
    </div>
    <div 
      class="mx-auto z-20" 
      :class="[
        selectedLanguage !== 'cn' 
          ? 'pt-[12%] pb-[16%] tablet:pt-[13%] tablet:pb-[16%] xl:pt-[16%] xl:pb-[21%]'
          : 'pt-[21%] pb-[26%]',
        selectedLanguage === 'jp' ? 'w-[426px]' : 'w-[400px]'
      ]"
    >
      <div v-if="selectedLanguage === 'en'">
        <CompleteEng />
      </div>
      <div v-if="selectedLanguage === 'kr'">
        <CompleteKor />
      </div>
      <div v-if="selectedLanguage === 'cn'">
        <CompleteChi />
      </div>
      <div v-if="selectedLanguage === 'jp'">
        <CompleteJap />
      </div>
    </div>
  </div>
</template>

<script>
import Lottie from '../../../node_modules/vue-lottie/src/lottie.vue';
import CompleteEng from './components/completeEng.vue';
import CompleteKor from './components/completeKor.vue';
import CompleteChi from './components/completeChi.vue';
import CompleteJap from './components/completeJap.vue';

export default {
  name: 'PublishingComplete',
  components: {
    CompleteEng,
    CompleteKor,
    CompleteChi,
    CompleteJap,
    'lottie': Lottie,
  },
  data() {
    return {
      selectedLanguage: 'en',
      languages: [
        { code: 'en', name: 'English' },
        { code: 'kr', name: 'Korean' },
        { code: 'cn', name: 'Chinese' },
        { code: 'jp', name: 'Japanese' },
      ],
      defaultOptions: {
        animationData: require('@/assets/publishing/celebration.json'),
        loop: false,
      },
    };
  },
  created(){
    if (this.$route.query.lang) this.selectedLanguage = this.$route.query.lang;
    else this.selectedLanguage = 'en';
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      this.anim.setSpeed(1.5);
    },
  }
};
</script>

<style lang="scss">

</style>
