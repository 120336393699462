<template>
  <div class="w-full font-NotoSansJp">
    <div 
      class="mx-auto font-black text-center text-black
        h-[86px] text-[34px] leading-[1.26] -tracking-[2px]
        tablet:h-[120px] tablet:text-[49px] tablet:leading-[1.22]"
    >
      <p>ゲームの提出が</p>
      <p>完了いたしました!</p>
    </div>
    <div class="h-[1px] mx-16 my-4 tablet:mx-0 tablet:my-6 bg-gray-200" />
    <div 
      class="text-center text-gray-600
        text-[15px] leading-[1.6] -tracking-[1px]
        tablet:text-xl tablet:-tracking-[1px]"
    >
      ゲームをご提出いただきありがとうございます。
      <br>
      近日中にご連絡いたします。
    </div>
    <div class="mt-5 tablet:mt-8 w-full text-center">
      <router-link 
        to="/" 
      >
        <button
          type="submit"
          class="bg-orange-500 font-bold text-white hover:bg-orange-600
            w-[134px] h-12 px-10 pt-[11px] pb-[13px] rounded-[24px] text-[18px] leading-[1.33] -tracking-[0.1px]
            tablet:w-[200px] tablet:h-16 tablet:px-16 tablet:pt-4 tablet:pb-[18px] tablet:rounded-5xl tablet:text-[24px] tablet:leading-[30px] tablet:-tracking-[0.2px]
            "
        >
          ホーム
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompleteJap',
};
</script>
