<template>
  <div class="w-full">
    <div 
      class="mx-auto h-[86px] text-[34px] leading-[1.26] -tracking-[0.57px] font-black text-center text-black
        tablet:h-[120px] tablet:text-[49px] tablet:leading-[1.22] tablet:-tracking-[0.82px] "
    >
      <p>Submission</p>
      <p class="text-orange-500">
        Complete!
      </p>
    </div>
    <div class="h-[1px] mx-18 my-4 bg-gray-200 tablet:mx-0 tablet:my-6" />
    <div class="text-center text-gray-600 text-[15px] leading-[1.6] -tracking-[0.09px] tablet:text-xl">
      Thank you for submitting your game.
      <br>
      We will make sure to contact you soon!
    </div>
    <div class="w-full text-center mt-5 tablet:mt-8">
      <router-link 
        to="/" 
      >
        <button
          type="submit"
          class="rounded-5xl bg-orange-500 font-bold text-white hover:bg-orange-600
            w-[248px] h-12 px-9 py-3 text-lg leading-[1.33]
            tablet:w-[302px] tablet:h-16 tablet:px-9 tablet:py-[17px] tablet:text-[24px] tablet:leading-[30px] tablet:-tracking-[0.2px]"
        >
          Return to Main Page
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompleteEng',
};
</script>
