<template>
  <div class="w-full">
    <div 
      class="mx-auto h-[86px] text-[34px] leading-[1.26] -tracking-[0.57px] font-black text-center text-black
        tablet:h-[120px] tablet:text-[49px] tablet:leading-[1.22] tablet:-tracking-[0.82px] "
    >
      <p>게임 제출이</p>
      <p><span class="text-orange-500">완료</span>되었어요!</p>
    </div>
    <div class="h-[1px] mx-14 my-4 tablet:mx-0 tablet:my-6 bg-gray-200" />
    <div class="text-center text-gray-600 text-[15px] leading-[1.6] -tracking-[0.09px] tablet:text-xl">
      게임을 제출해 주셔서 감사드려요.
      <br>
      빠른 시일 내에 연락드릴게요!
    </div>
    <div class="w-full text-center mt-5 xl:mt-8">
      <router-link 
        to="/" 
      >
        <button
          type="submit"
          class="rounded-5xl bg-orange-500 font-bold text-white hover:bg-orange-600
            w-[208px] h-12 px-9 py-3 text-lg leading-[1.33]
            tablet:w-[250px] tablet:h-16 tablet:px-9 tablet:py-[17px] tablet:text-[24px] tablet:leading-[30px] tablet:-tracking-[0.2px]"
        >
          메인으로 이동하기
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompleteKor',
};
</script>
